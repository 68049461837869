// TableHeader.jsx
import React, { Children } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

const TableHeading = ({ className, label, sortedColumn, sortOrder, handleSort, children, columnId, labelClassName }: any) => {
    return (
        <th className={className} onClick={() => handleSort(columnId)}
        // style={{borderRadius:0}}
        >
            <div className={`${labelClassName} table-arrow`}>
                {label}
                {sortedColumn === columnId && (
                    <>
                        {sortOrder === 'desc' ? <FaArrowDown  className='ms-2'/> : <FaArrowUp className='ms-2' />}
                    </>
                )}
            </div>
            {children}
        </th>
    );
};

export default TableHeading;
