import { useIntl } from 'react-intl';
import { constraintConfig } from '../../../../constraintConfig';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { MenuItem } from './MenuItem';
import { allRoutes } from '../../../../app/routing/All_Routes';

export function MenuInner() {
  const { roleID, reportPermision } = useAppSelector(selectAuth);

  const intl = useIntl()
  return (
    <>
      {(roleID !== constraintConfig.roleID.role10 && roleID !== constraintConfig.roleID.roleIDPortalSupport) && (
        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      )}
      {(roleID === constraintConfig.roleID.role10) && (
        <MenuItem title='Expense Approval' to='/ap-approved' />
      )}
      {(roleID === constraintConfig.roleID.role4 || roleID === constraintConfig.roleID.role5 || roleID === constraintConfig.roleID.role2) && (
        <>
          <MenuItem title='Expense Wizard' to={allRoutes.expenseWizard} />
          <MenuItem title='Expense Line Items' to='/expense-line-items' />
          <MenuItem title='Expense Reports' to={allRoutes.expenseReports} />
          <MenuItem title='Receipt Vault' to='/receipt-vault' />
        </>
      )}
      {(roleID === constraintConfig.roleID.role5) && (
        <>
          <MenuItem title='My Budget History' to='/budget-history' />
        </>
      )}
      {(roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.role4) && (
        <MenuItem title='Approval Queue' to='/approval-queue' />
      )}
      {(roleID === constraintConfig.roleID.role4) && (
        <>
          <MenuItem title='Team Management' to='/team-management' />
          {/* <MenuItem title='Backup Approvers' to={allRoutes.backupApprovers} /> */}
        </>
      )}
      {(roleID === constraintConfig.roleID.role4 || roleID === constraintConfig.roleID.role5 || roleID === constraintConfig.roleID.role2) && (
        <MenuItem title='Cards' to='/card' />
      )}

      {/* {(roleID === constraintConfig.roleID.role4 || roleID === constraintConfig.roleID.role2) && (
        <MenuItem title='Vendor' to='/vendor' />
      )} */}
      {roleID !== constraintConfig.roleID.role10 &&
        roleID !== constraintConfig.roleID.role1 &&
        roleID !== constraintConfig.roleID.role5 &&
        roleID !== constraintConfig.roleID.roleIDPortalSupport && (
          // <MenuItem title='Report Analytics' to={`${allRoutes.analytics}' />
          <>
            <MenuInnerWithSub
              title='Analytics'
              to={allRoutes.analytics}
              // icon='/media/icons/duotune/communication/com006.svg'
              // hasArrow={true}
              menuPlacement='bottom-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to={`${allRoutes.analytics}/my-report/expense-summary`} title='My Reports' hasBullet={true} />
              {roleID === constraintConfig.roleID.role4 &&
                <MenuItem to={`${allRoutes.analytics}/team-report/expense-summary`} title='Team Reports' hasBullet={true} />
              }
               {roleID === constraintConfig.roleID.role2 &&
                    <MenuItem to={`${allRoutes.analytics}/org-report/expense-summary`} title='Org Reports' hasBullet={true} />
               }
              {reportPermision === true &&
                <>
                  {roleID === constraintConfig.roleID.role4 &&
                    <MenuItem to={`${allRoutes.analytics}/org-report/expense-summary`} title='Org Reports' hasBullet={true} />
                  }
                </>
              }
            </MenuInnerWithSub>
          </>
        )
      }
      {roleID === constraintConfig.roleID.role5 &&
        <MenuItem title='Analytics' to={`${allRoutes.analytics}/my-report/expense-summary`} />
      }
      {reportPermision === true &&
        <>
          {roleID === constraintConfig.roleID.role10 &&
            <MenuItem title='Analytics' to={`${allRoutes.analytics}/org-report/expense-summary`} />
          }
        </>
      }
      {/* {(roleID === constraintConfig.roleID.role2) && (
        <MenuItem title='Workflow' to='/workflow' />
      )} */}


      {/* {(roleID === constraintConfig.roleID.role2) && (
        <MenuItem title='Manager Expense' to='/approvers' />
      )} */}

      {/* <MenuInnerWithSub title='Workflow' to='/workflow' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/workflow/workflow'
          title='Workflow'
        />
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/workflow/approvers'
          title='Approvers'
        />
      </MenuInnerWithSub> */}

      {
        (roleID === constraintConfig.roleID.role1) && (
          <MenuItem title='Organizations' to='/organization' />
        )
      }
      {
        (roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) &&
        <>
          <MenuItem title={roleID === constraintConfig.roleID.role2 ? 'Expense Users' : "Admin Users"} to='/expense-users' />
        </>
      }
      {
        (roleID === constraintConfig.roleID.role1) && (
          <MenuItem title='Roles' to='/roles' />
        )
      }
      {
        (roleID === constraintConfig.roleID.role2) && (
          <>
            <MenuInnerWithSub
              title='Settings'
              to='/settings'
              // icon='/media/icons/duotune/communication/com006.svg'
              // hasArrow={true}
              menuPlacement='bottom-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to='/settings/org-settings' title='Org Settings' hasBullet={true} />
              <MenuItem to='/settings/vendor' title='Vendor' hasBullet={true} />
              <MenuItem to='/settings/workflow' title='Workflow' hasBullet={true} />
              <MenuItem to='/settings/mileage' title='Mileage' hasBullet={true} />
              <MenuInnerWithSub
                title='Budget Allocation'
                to='/budget-allocation'
                // icon='/media/icons/duotune/communication/com006.svg'
                hasArrow={true}
                hasBullet={true}
                menuPlacement='right-start'
                menuTrigger={`{default:'click', lg: 'hover'}`}
              >
                <MenuItem to='/budget-allocation/budget' title='User Flex Budget' hasBullet={true} />
                <MenuItem to='/budget-allocation/category-budgeting' title='Category Budget Allocation' hasBullet={true} />
              </MenuInnerWithSub>

              <MenuItem to='/settings/categories' title='Categories' hasBullet={true} />
              <MenuItem to='/settings/locations' title='Locations' hasBullet={true} />
              <MenuItem to='/settings/departments' title='Departments' hasBullet={true} />
              <MenuItem to='/settings/division' title='Divisions' hasBullet={true} />
              <MenuItem to='/settings/countries' title='Countries' hasBullet={true} />
              {/* <MenuItem to='/settings/expense-type-code' title='Expense Codes/Cost Center' hasBullet={true} /> */}
              <MenuItem to='/settings/expense-violation' title='Expense Violation' hasBullet={true} />
              <MenuItem to='/settings/automation/bank-cc-feeds' title='Automation - Bank CC Feeds' hasBullet={true} />
              <MenuItem to='/settings/automation/user-cc-feeds' title='Automation - User CC Feeds' hasBullet={true} />
              <MenuItem to='/settings/automation/merge-line-items' title='Merge Line Items' hasBullet={true} />

              <MenuItem to='/settings/request-logs' title='Request Logs' hasBullet={true} />

              {/* <MenuItem to='/settings/portal-support-requests' title='Portal Support Requests' hasBullet={true} /> */}

              {/* <MenuInnerWithSub
              title='Automation CC'
              to='/settings/automation'
              // icon='/media/icons/duotune/communication/com006.svg'
              hasArrow={true}
              hasBullet={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to='/settings/automation/bank-cc-feeds' title='Automation - Bank CC Feeds' hasBullet={true} />
              <MenuItem to='/settings/automation/automation-cc-feeds-stats-enhancements' title=' Automation CC Feeds Stats Enhancements' hasBullet={true} />
            </MenuInnerWithSub> */}
              {/* <MenuItem to='' title='Currency' hasBullet={true} /> */}
              {/* <MenuItem to='/department-location' title='Departments Location' hasBullet={true} /> */}
              {/* <MenuItem to='/active-expense-violation' title='Active Expense Violation' hasBullet={true} /> */}
              {/* <MenuItem to='' title='Expense Types' hasBullet={true} /> */}
              {/* <MenuItem to='' title='Integrations' hasBullet={true} /> */}
              {/* <MenuItem to='' title='Org Profile' hasBullet={true} /> */}
              {/* <MenuItem to='' title='Payment Methods' hasBullet={true} /> */}
              {/* <MenuItem to='' title='Roles – Read only' hasBullet={true} /> */}
            </MenuInnerWithSub>
          </>
        )
      }
      {
        (roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.roleIDPortalSupport) && (
          <MenuItem to='/portal-support-requests' title='Portal Support Requests' />
        )
      }

      {
        (roleID === constraintConfig.roleID.role4) && (
          <>
            <MenuInnerWithSub
              title='Budget Allocation'
              to='/budget-allocation'
              // icon='/media/icons/duotune/communication/com006.svg'
              // hasArrow={true}
              menuPlacement='bottom-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              <MenuItem to='/budget-allocation/budgets' title='User Flex Budget' hasBullet={true} />
              <MenuItem to='/budget-allocation/budget-history' title='My Budget History' hasBullet={true} />
              <MenuItem to='/budget-allocation/category-budgeting' title='Category Budget Allocation' hasBullet={true} />
            </MenuInnerWithSub>
          </>
        )
      }

      {/* <MenuItem title='Google Map' to='/map' /> */}

      {/* <MenuItem title='Layout Builder' to='/builder' /> */}



      {/* <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          icon='/media/icons/duotune/general/gen022.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Errors'
          to='/error'
          icon='/media/icons/duotune/technology/teh004.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>

        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub> */}

      {/* <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
      
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='/media/icons/duotune/communication/com012.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem
          icon='/media/icons/duotune/general/gen051.svg'
          to='/apps/user-management/users'
          title='User management'
        />
      </MenuInnerWithSub> */}
      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
