import Select from 'react-select'
import { constraintConfig } from '../../../../../constraintConfig'
import labelKey from '../../../../localization/label.json'
import { useAppSelector } from '../../../../../hooks'
import { selectAuth } from '../../../../../features/authSlice'
import SelectField from '../../../../../components/textFields/SelectField'
import TextField from '../../../../../components/textFields/TextField'
import SearchSelect from '../../../../../components/textFields/SearchSelect'
type Props = {
  formik: any
  getAllOrganizationLookUp: any
  getAllRolesData: any
  getAllExpenseType: any
  getAllManger: any
  departmentLookup: any
  designationLookup: any
  getAllLocationType: any
  locationsData: any
  getAlldivision: any
}
const Step1 = ({ formik,
  getAllOrganizationLookUp,
  getAllRolesData,
  getAllExpenseType,
  getAllManger,
  departmentLookup,
  designationLookup,
  getAllLocationType,
  locationsData,
  getAlldivision }: Props) => {
  const { roleID } = useAppSelector(selectAuth);

  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='row fv-row'>
          {roleID === constraintConfig.roleID.role1 &&
            <>
              <div className="col-md-12 mb-5">
                <label className='form-label fs-5 fw-bold required '>{labelKey.organization}</label>
                <Select
                  options={[
                    ...(getAllOrganizationLookUp?.result || []).map((option: any, index: any) => ({
                      value: option.id,
                      label: option?.value,
                    })),
                  ]}
                  placeholder='Select Organization'
                  onChange={(selectedOption: any) => {
                    formik.setFieldValue('orgId', selectedOption.value);
                  }}
                />
              </div>

            </>
          }
          {/* ${roleID === constraintConfig.roleID.role1 ? '6' : '12'} */}
          <div className={`col-md-4 mb-5`}>
            {/* <SelectField
              label={labelKey.role}
              required={true}
              selectClass={`${formik.touched.role?.roleID && formik.errors.role?.roleID ? 'is-invalid' : ''
                }`}
              {...formik.getFieldProps('role.roleID')}
            >
              <option value="">{labelKey.selectRole}</option>
              <>
                {roleID === constraintConfig.roleID.role1 && (
                  <>
                    {getAllRolesData?.result.map((option: any, index: any) => {
                      if (option.value === "Administrator" && option.id === 1 ||
                         option.value === "Manager" && option.id === 4 ||
                          option.value === "Accounts Payable" && option.id === 10 ||
                           option.value === "User" && option.id === 5) {
                        return null; // Skip rendering the option for "Administrator" with id equal to 1
                      }
                      return <option key={index} value={option.id}>{option.value}</option>;
                    })}
                  </>
                )}
                {roleID === constraintConfig.roleID.role2 && (
                  <>
                    {getAllRolesData?.result.map((option: any, index: any) => {
                      if (option.value === "Administrator" && option.id === 1 || option.value === "Org Admin" && option.id === 2) {
                        return null; // Skip rendering the option for "Administrator" with id equal to 1
                      }
                      return <option key={index} value={option.id}>{option.value}</option>;
                    })}
                  </>
                )}
                {roleID === constraintConfig.roleID.role4 && (
                  <>
                    {getAllRolesData?.result.map((option: any, index: any) => {
                      if (option.value === "Administrator" && option.id === 1 || option.value === "Org Admin" && option.id === 2 || option.value === "Manager" && option.id === 4 || option.value === "Accounts Payable" && option.id === 10) {
                        return null; // Skip rendering the option for "Administrator" with id equal to 1
                      }
                      return <option key={index} value={option.id}>{option.value}</option>;
                    })}
                  </>
                )}
              </>
            </SelectField> */}
            <SearchSelect
              label={labelKey.role}
              required
              options={[
                { value: '', label: labelKey.selectRole },
                ...(getAllRolesData?.result || [])
                  .filter((option: any) => {
                    // Filter out options based on roleID and constraints
                    if (roleID === constraintConfig.roleID.role1) {
                      return !(option.value === "Administrator" && option.id === 1 ||
                        option.value === "Manager" && option.id === 4 ||
                        option.value === "Accounts Payable" && option.id === 10 ||
                        option.value === "User" && option.id === 5);
                    } else if (roleID === constraintConfig.roleID.role2) {
                      return !(option.value === "Administrator" && option.id === 1 ||
                        option.value === "Org Admin" && option.id === 2);
                    } else if (roleID === constraintConfig.roleID.role4) {
                      return !(option.value === "Administrator" && option.id === 1 ||
                        option.value === "Org Admin" && option.id === 2 ||
                        option.value === "Manager" && option.id === 4 ||
                        option.value === "Accounts Payable" && option.id === 10);
                    }
                    return true; // Default case if no specific roleID matches
                  })
                  .map((option: any) => ({
                    value: option.id,
                    label: option.value,
                    key: option.id,
                  }))
              ]}
              placeholder="Select Type"
              value={
                formik.values?.role?.roleID
                  ? {
                    value: formik.values.role.roleID,
                    label: getAllRolesData?.result.find((option: any) => option.id === formik.values.role.roleID)?.value || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue('role.roleID', selectedOption.value);
                formik.setFieldValue('role.name', selectedOption.label);
                // Add condition for mgrApproveMaxLimit when roleID is not 4
                if (selectedOption.value !== 4) {
                  formik.setFieldValue('mgrApproveMaxLimit', 0);
                }
              }}
              selectClass={formik.touched.role?.roleID && formik.errors.role?.roleID ? 'is-invalid' : ''}
            />

          </div>
          <div className='col-md-4 mb-5'>
            <SearchSelect
              label={labelKey.allowedExpenseType}
              required
              options={[
                { value: 0, label: 'Select Type' },
                ...(getAllExpenseType?.result || []).map((option: any) => ({
                  value: option?.id,
                  label: option.value,
                  key: option.id,
                }))]}
              placeholder="Select Type"
              value={
                formik.values?.expenseType?.id
                  ? {
                    value: formik.values?.expenseType?.id,
                    label: formik.values?.expenseType?.value || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue('expenseType.id', selectedOption.value);
                formik.setFieldValue('expenseType.value', selectedOption.label);
              }}
            />
          </div>
          <div className='col-md-4 mb-5'>
            <SearchSelect
              label={labelKey.allowedMileageType}
              required
              options={[
                { value: 0, label: 'Select Type' },
                ...(getAllLocationType?.result || []).map((option: any) => ({
                  value: option?.id,
                  label: option.value,
                  key: option.id,
                }))]}
              placeholder="Select Type"
              value={
                formik.values?.locationType?.id
                  ? {
                    value: formik.values?.locationType?.id,
                    label: formik.values?.locationType?.value || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue('locationType.id', selectedOption.value);
                formik.setFieldValue('locationType.value', selectedOption.label);
              }}
            />
          </div>

          {['4', '5'].includes(String(formik.values?.role?.roleID)) && (
            <>
              {roleID !== constraintConfig.roleID.role5 && roleID !== constraintConfig.roleID.role4 && (
                <div className={`${String(formik.values?.role?.roleID) === String(constraintConfig.roleID.role5) ? 'col-md-6' : 'col-md-6'} mb-5`}>
                  <label className={`${String(formik.values?.role?.roleID) === String(constraintConfig.roleID.role5) ? 'required' : ''} form-label fs-5 fw-bold`}>
                    {labelKey.manager}&nbsp;
                    {String(formik.values?.role?.roleID) === String(constraintConfig.roleID.role4) ? <span className='fs-8 text-gray'>(optional)</span> : ''}
                  </label>
                  <Select
                    options={[
                      { value: 0, label: 'Select Manager' },
                      ...(getAllManger?.result || []).map((option: any) => ({
                        value: option.orgUserID,
                        label: option.firstName + (option.middleName ? ' ' + option.middleName : '') + ' ' + option.lastName,
                        key: option.id,
                      }))]}
                    placeholder='Select Manager'
                    value={
                      formik.values?.managerId
                        ? {
                          value: formik.values?.managerId,
                          label: formik.values?.managerName || '',
                        }
                        : null
                    }
                    onChange={(selectedOption: any) => {
                      formik.setFieldValue('managerId', selectedOption.value);
                      formik.setFieldValue('managerName', selectedOption.label);
                    }}
                  />
                </div>
              )}
            </>
          )}
          {String(formik.values?.role?.roleID) === '4' && (
            <div className="col-md-6 mb-5">
              <TextField
                label={labelKey.approval$Limit}
                iconTooltip="Current User Approval Limit"
                required={true}
                type='number'
                placeholder='Enter Approval Limit'
                labelClass={`${formik.touched.mgrApproveMaxLimit && formik.errors.mgrApproveMaxLimit ? 'is-invalid' : ''}`}
                {...formik.getFieldProps('mgrApproveMaxLimit')}
              />
            </div>
          )}

          <div className='col-md-6 b-5'>
            <label className='fs-5 fw-bold mb-2 required'>
              {labelKey.officeLocation}
            </label>
            <Select
              options={(locationsData?.result || []).map((option: any, index: any) => ({
                value: option.id,
                label: option?.value,
                key: option.id,
              }))}
              value={
                formik.values.location?.id
                  ? {
                    value: formik.values.location?.id,
                    label: formik.values.location?.value || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue(`location.id`, selectedOption?.value || null);
                formik.setFieldValue(`location.value`, selectedOption?.label || null);
              }}
            />

          </div>
          <div className="col-md-6 mb-5">
            <label className='form-label fs-5 fw-bold'>{labelKey.department}</label>
            <Select
              options={[
                ...(departmentLookup?.result || []).map((option: any, index: any) => ({
                  value: option.id,
                  label: option?.value,
                })),
              ]}
              placeholder='Select Department'
              value={
                formik.values.department?.id
                  ? {
                    value: formik.values.department?.id,
                    label: formik.values.department?.title || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue(`department.id`, selectedOption?.value || null);
                formik.setFieldValue(`department.title`, selectedOption?.label || null);
              }}
            />
          </div>
          <div className="col-md-6 mb-5">
            <label className='form-label fs-5 fw-bold'>{labelKey.division}</label>
            <Select
              options={[
                ...(getAlldivision?.result || []).map((option: any, index: any) => ({
                  value: option.id,
                  label: option?.value,
                })),
              ]}
              placeholder='Select Division'
              value={
                formik.values.division?.id
                  ? {
                    value: formik.values.division?.id,
                    label: formik.values.division?.title || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue(`division.id`, selectedOption?.value || null);
                formik.setFieldValue(`division.title`, selectedOption?.label || null);
              }}
            />
          </div>
          <div className="col-md-6 mb-5">
            <label className='form-label fs-5 fw-bold'>{labelKey.designation}</label>
            <Select
              options={[
                ...(designationLookup?.result || []).map((option: any, index: any) => ({
                  value: option.id,
                  label: option?.value,
                })),
              ]}
              placeholder='Select Designation'
              value={
                formik.values.designation?.id
                  ? {
                    value: formik.values.designation?.id,
                    label: formik.values.designation?.title || '',
                  }
                  : null
              }
              onChange={(selectedOption: any) => {
                formik.setFieldValue(`designation.id`, selectedOption?.value || null);
                formik.setFieldValue(`designation.title`, selectedOption?.label || null);
              }}
            />
          </div>
          {String(formik.values?.role?.roleID) === constraintConfig.roleID.role4.toString() ||
            String(formik.values?.role?.roleID) === constraintConfig.roleID.role10.toString() ? (
            <div className="col-md-4 mb-5">
              <label className='form-label fs-5 fw-bold'>{labelKey.orgReportsPermision}</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input cursor-pointer mt-3"
                  type="checkbox"
                  role="switch"
                  id={`flexSwitchCheckChecked`}
                  {...formik.getFieldProps('reportPermision')}
                  checked={formik.values.reportPermision}
                />
              </div>
            </div>) : null}


        </div>
      </div>
    </div>
  )
}

export { Step1 }

