import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
import { useRecallExpenseReportMutation, useUpdateExpenseStatusMutation } from '../../../services/ExpenseApi'
import labelKey from "../../localization/label.json";
import tosterKey from "../../localization/toster.json";

type Props = {
    show: boolean
    handleClose: () => void
    data: any
    handleCloseModals?: any
    recallExpense?: any

}
const ConfirmationExpenseModal = ({ show, handleClose, data, handleCloseModals, recallExpense }: Props) => {
    const [updateExpenseStatus, { data: updateExpenseStatusData, isLoading, isSuccess, isError, error }] = useUpdateExpenseStatusMutation()
    const [recallApi, { data: recallApiData, isLoading: recallApiIsLoading, isSuccess: recallApiIsSuccess, isError: recallApiIsError, error: recallApiError }] = useRecallExpenseReportMutation()


    const sendForApproval = async (expenseId: any) => {
        try {
            // Make the API call to update the expense status
            const response = await updateExpenseStatus({ expenseId, expenseStatus: { id: 2, title: "Pending for Approval" } });
        } catch (error) {
        }
        handleClose();
    };

    const recallAPi = async (expenseId: any) => {
        try {
            // Make the API call to update the expense status
            const response = await recallApi({ expenseId: expenseId });
        } catch (error) {
        }
        handleClose();
    };
    // useEffect(() => {
    //     if (isSuccess) {
    //         toast.success(tosterKey.statusUpdatedSuccessfully);
    //         handleClose()
    //         if (handleCloseModals) {
    //             handleCloseModals();
    //         }
    //     }
    // }, [isSuccess]);
    useEffect(() => {
        if (isSuccess) {
            const responseData = (updateExpenseStatusData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose()
            if (handleCloseModals) {
                handleCloseModals();
            }
        }
    }, [isSuccess, updateExpenseStatusData]);

    useEffect(() => {
        if (recallApiIsSuccess) {
            const responseData = (recallApiData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
        }
    }, [recallApiIsSuccess, recallApiData]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (recallApiIsError && recallApiError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in recallApiError && recallApiError.data && typeof recallApiError.data === 'object' && 'message' in recallApiError.data) {
                errorMessage = (recallApiError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [recallApiIsError, recallApiError]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header bg-light py-4'>
                    <h2 className='mb-0'>{labelKey.confirmationStatus}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>


                <div className='modal-body py-lg-6 px-lg-6'>
                    {recallExpense ?
                        <p className='fs-5 fw-semibold'>Are you sure you want to recall the Expense Report  <span className='text-primary'>"{recallExpense?.trackingId}"</span></p>
                        :
                        <p className='fs-5 fw-semibold'>{labelKey.areYoSureToProceed} <span className='text-primary'>"{labelKey.submitForApproval}"</span>?</p>
                    }
                </div>
                <div className='modal-footer justify-content-center py-4'>
                    <button className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</button>
                    {recallExpense ?
                        <button
                            className='btn btn-primary'
                            type='submit'
                            onClick={() => recallAPi(recallExpense?.expenseId)}
                            disabled={recallApiIsLoading}
                        >
                            {!recallApiIsLoading && <span className='indicator-label'>{labelKey.yes}</span>}
                            {recallApiIsLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button> :
                        <button
                            className='btn btn-primary'
                            type='submit'
                            onClick={() => sendForApproval(data)}
                            disabled={isLoading}
                        >
                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>}
                </div>
            </Modal>
        </>
    )
}

export default ConfirmationExpenseModal