import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { IoIosRepeat } from 'react-icons/io';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { PageTitle } from '../../../../_metronic/layout/core';
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp';
import DateRangeComp from '../../../../components/dateComponent/DateRangeComp';
import DateTimeComp from '../../../../components/dateComponent/DateTimeComp';
import FilterMenu from '../../../../components/filterMenu/FilterMenu';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetBankFilesLineitemsV2Query } from '../../../../services/AutomationApi';
import { useGetAllUsersManagerAdminQuery } from '../../../../services/GeneralApi';
import labelKey from '../../../localization/label.json';
import BankCCFeedDetailViewModal from './BankCCFeedDetailViewModal';
import { Spinner } from 'react-bootstrap';
import { useGetVendorLookUpQuery } from '../../../../services/VendorApi';
import SearchSelect from '../../../../components/textFields/SearchSelect';

const { Option } = components;
type Option = {
    value: string;
    label: string;
};

// Custom Option component to add checkboxes
const CheckboxOption = (props: any) => {
    return (
        <Option {...props} className='d-flex align-items-start gap-2'>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </Option>
    );
};

const UserCCFeeds = () => {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(15);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [trackingIdSearchStr, setTrackingIdSearchStr] = useState(''); // debounce search
    const [immediateTrackingIdSearchStr, setImmediateTrackingIdSearchStr] = useState('');
    const [accountNoSearchStr, setAccountNoSearchStr] = useState(''); // debounce search
    const [immediateAccountNoSearchStr, setImmediateAccountNoSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("itemImportDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("itemImportDate");
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [filterByTimePeriod, setFilterByTimePeriod] = useState('');
    const [filterByUserIds, setFilterByUserIds] = useState<string[]>([]);
    const [filterByVendor, setFilterByVendor] = useState("");
    const [localFilterByVendor, setLocalFilterByVendor] = useState("");
    const [selectedUserIds, setSelectedUserIds] = useState<Option[]>([]);
    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByTimePeriod, setLocalFilterByTimePeriod] = useState("");
    const [localFilterByUserIds, setLocalFilterByUserIds] = useState<string[]>([]);
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    // Debounced search state
    // const [searchStr, setSearchStr] = useState('');
    const { data: getUserByManager } = useGetAllUsersManagerAdminQuery('')
    const { data: vendor, refetch: vendorRefetch } = useGetVendorLookUpQuery('')

    const { data, isLoading, refetch } = useGetBankFilesLineitemsV2Query({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        trackingId: trackingIdSearchStr,
        accountNo: accountNoSearchStr,
        vendorId: filterByVendor,
        sortBy: sortBy,
        sortOrder: sortOrder,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        timePeriod: filterByTimePeriod,
        userIds: filterByUserIds.length > 0 ? filterByUserIds : null,

    })

    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };
    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    const handleTrackingIdSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateTrackingIdSearchStr(value);

        // delayTrackingIdSearch(value); 
    };
    // const delayTrackingIdSearch = useCallback(
    //     debounce((searchValue) => {
    //         setTrackingIdSearchStr(searchValue);
    //     }, 500),
    //     []
    // );
    const handleAccountNoSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateAccountNoSearchStr(value);

        // delayAccountNoSearch(value); 
    };
    // const delayAccountNoSearch = useCallback(
    //     debounce((searchValue) => {
    //         setAccountNoSearchStr(searchValue);
    //     }, 500),
    //     []
    // );

    useEffect(() => {
        if (searchStr !== "" && sortOrder !== "" && sortBy !== "" && trackingIdSearchStr !== "") {
            // Trigger the API call after the debounce time
            refetch();
        }
    }, [searchStr, sortOrder, sortBy, refetch]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(['sr', 'user', 'fileName', 'totalAmountOfDebits', 'totalParsedAmount', 'itemImportDate', 'ccTotalAmount', 'itemDescription', 'totalLineItemCreatedAmount', 'parsingStatus', 'accountNumber', 'bankVendorName', 'actions', 'expenseReportId', 'bcEmployeeId', 'expenseCategory']);
    const handleToggleColumn = (columnId: string) => {
        if (hiddenColumns.includes(columnId)) {
            setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
        } else {
            setHiddenColumns([...hiddenColumns, columnId]);
        }
    };

    const [showBankFeedDetailModal, setShowBankFeedDetailModal] = useState(false)
    const [userBankFeedData, setUserBankFeedData] = useState();

    const bankFeedDetailHandler = () => {
        setShowBankFeedDetailModal((prev) => !prev);
    };
    useEffect(() => {
        if (showBankFeedDetailModal === false) {
            refetch()
        }
    }, [showBankFeedDetailModal])
    const handleRefresh = () => {
        refetch()
            .then(() => {
                toast.success('Data Refresh successfully!');
            });
    };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
        setLocalFilterByTimePeriod('')
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByUserIds(localFilterByUserIds)
        setFilterByTimePeriod(localFilterByTimePeriod)
        setTrackingIdSearchStr(immediateTrackingIdSearchStr)
        setAccountNoSearchStr(immediateAccountNoSearchStr)
        setFilterByVendor(localFilterByVendor)
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByUserIds([])
        setFilterByTimePeriod('')
        setTrackingIdSearchStr('')
        setAccountNoSearchStr('')
        setFilterByVendor('')

        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByUserIds([])
        setLocalFilterByTimePeriod('')
        setLocalFilterByVendor('')
        setImmediateAccountNoSearchStr('')
        setImmediateTrackingIdSearchStr('')

        setSelectedUserIds([])

    }

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);
    const userIdsOptions: Option[] = getUserByManager?.result?.map((userByManager: any) => ({
        value: userByManager.id,
        label: userByManager.value,
    }));
    const handleUserIdsChange = (selected: Option[]) => {
        setSelectedUserIds(selected);
        setLocalFilterByUserIds(selected?.map((userByManager) => userByManager.value));
    };
    const handleTimePeriodChange = (selectedOption: any) => {
        setLocalFilterByTimePeriod(selectedOption.value);
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
    };
    const handleVendorChange = (e: any) => {
        setLocalFilterByVendor(e.value);
    };
    return (
        <>
            <BankCCFeedDetailViewModal
                show={showBankFeedDetailModal}
                handleClose={() => setShowBankFeedDetailModal(false)}
                userBankFeedData={userBankFeedData}
            />
            <PageTitle breadcrumbs={[]}>Automation</PageTitle>

            <div className='card mb-5'>
                {/* <div className='card-header border-0 pt-6'>

                    <div className='d-flex align-items-center '>
                        <SearchBarComponent
                            placeholder='File Parsing'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                        <Tooltip id="refreshData" place="top" />
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <IoIosRepeat className='h-20px w-20px' />
                        </div>
                    </div>
                    <FilterMenu
                        onclickApplyFilters={applyFilters}
                        onclickResetFilters={resetFilters}
                        isApplyingFiltersLoading={isApplyingFilters}
                    >
                        <>
                            <div className='mb-5'>
                                <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                                <Select
                                    isMulti
                                    options={userIdsOptions}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{ Option: CheckboxOption }}
                                    onChange={(selected) => handleUserIdsChange(selected as Option[])} // Casting to Option[]
                                    value={selectedUserIds} // Directly use selectedDeptId as it is of type Option[]
                                    placeholder='Search User...'
                                    className='filter-valueContainer'
                                />
                            </div>
                            <div className='mb-5'>
                                <DateRangeComp
                                    startDate={localFilterByStartDate || moment()}
                                    endDate={localFilterByEndDate || moment()}
                                    onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                    value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                            </div>
                        </>
                    </FilterMenu>

                </div> */}
                <div className='card-body py-8'>
                    <div className="row align-items-end">
                        {/* <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <SearchBarComponent
                                placeholder='File Parsing'
                                value={immediateSearchStr}
                                onChange={handleSearchChange}
                            />
                        </div> */}
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                        <label className='form-label fs-6 fw-bold'>{labelKey.reportId}:</label>
                            <SearchBarComponent
                                placeholder='Tracking ID'
                                value={immediateTrackingIdSearchStr}
                                onChange={handleTrackingIdSearchChange}
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                        <label className='form-label fs-6 fw-bold'>{labelKey.accountNumber}:</label>
                            <SearchBarComponent
                                placeholder='Account No'
                                value={immediateAccountNoSearchStr}
                                onChange={handleAccountNoSearchChange}
                                className='w-100'
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <SearchSelect
                                label={`${labelKey.vendor}:`}
                                options={[
                                    { value: '', label: 'Select Vendor' }, // Add the empty option
                                    ...(Array.isArray(vendor?.result) ? vendor.result.map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })) : [])
                                ]}
                                placeholder={labelKey.selectVendor}
                                value={localFilterByVendor && localFilterByVendor !== ''
                                    ? {
                                        value: localFilterByVendor,
                                        label: vendor?.result.find((option: any) => option.id === localFilterByVendor)?.value
                                    }
                                    : null
                                }
                                onChange={handleVendorChange}
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                            <Select
                                isMulti
                                options={userIdsOptions}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{ Option: CheckboxOption }}
                                onChange={(selected) => handleUserIdsChange(selected as Option[])} // Casting to Option[]
                                value={selectedUserIds} // Directly use selectedDeptId as it is of type Option[]
                                placeholder='Search User...'
                                className='filter-valueContainer'
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <DateRangeComp
                                startDate={localFilterByStartDate || moment()}
                                endDate={localFilterByEndDate || moment()}
                                onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <div className='d-flex align-items-end h-100 gap-3'>
                                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>

                                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                    {isApplyingFilters ? <>
                                        <span>loading...</span>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </> : "Apply Filters"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className="w-60px ps-2"
                                            />
                                        )}
                                        {hiddenColumns.includes('user') && (
                                            <TableHeading
                                                label={labelKey.user}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='user'
                                            />
                                        )}
                                        {hiddenColumns.includes('fileName') && (
                                            <TableHeading
                                                label={labelKey.fileName}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='fileName'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('bcEmployeeId') && (
                                            <TableHeading
                                                label={labelKey.bcEmployeeId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='bcEmployeeId'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseReportId') && (
                                            <TableHeading
                                                label={labelKey.reportId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseReportId'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('expenseCategory') && (
                                            <TableHeading
                                                label={labelKey.category}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='expenseCategory'
                                                className="w-400px"
                                            />
                                        )}
                                        {hiddenColumns.includes('parsingStatus') && (
                                            <TableHeading
                                                label={labelKey.importStatus}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='parsingStatus'
                                            />
                                        )}
                                        {hiddenColumns.includes('accountNumber') && (
                                            <TableHeading
                                                label={labelKey.accountNumber}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='accountNumber'
                                            />
                                        )}
                                        {hiddenColumns.includes('bankVendorName') && (
                                            <TableHeading
                                                label={labelKey.vendor}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='bankVendorName'
                                            />
                                        )}
                                        {hiddenColumns.includes('itemImportDate') && (
                                            <TableHeading
                                                label={labelKey.itemImportDate}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='itemImportDate'
                                                className="w-100px"
                                            />
                                        )}
                                        {hiddenColumns.includes('ccTotalAmount') && (
                                            <TableHeading
                                                label={labelKey.ccTotalAmount}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='ccTotalAmount'
                                                className="w-100px"
                                            />
                                        )}
                                        {hiddenColumns.includes('itemDescription') && (
                                            <TableHeading
                                                label={labelKey.itemDescription}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='itemDescription'
                                                className="w-100px"
                                            />
                                        )}
                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {/* {hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )} */}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.user}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("user")}
                                                    checked={hiddenColumns.includes('user')}
                                                    fieldClass='mb-4'
                                                />

                                                <TextField
                                                    rightLabel={labelKey.fileName}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("fileName")}
                                                    checked={hiddenColumns.includes('fileName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.bcEmployeeId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("bcEmployeeId")}
                                                    checked={hiddenColumns.includes('bcEmployeeId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.reportId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseReportId")}
                                                    checked={hiddenColumns.includes('expenseReportId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.category}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("expenseCategory")}
                                                    checked={hiddenColumns.includes('expenseCategory')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.importStatus}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("parsingStatus")}
                                                    checked={hiddenColumns.includes('parsingStatus')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.accountNumber}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("accountNumber")}
                                                    checked={hiddenColumns.includes('accountNumber')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.vendor}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("bankVendorName")}
                                                    checked={hiddenColumns.includes('bankVendorName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemImportDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemImportDate")}
                                                    checked={hiddenColumns.includes('itemImportDate')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.ccTotalAmount}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("ccTotalAmount")}
                                                    checked={hiddenColumns.includes('ccTotalAmount')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.itemDescription}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("itemDescription")}
                                                    checked={hiddenColumns.includes('itemDescription')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading &&
                                        <>
                                            {data?.result?.data?.length > 0 ? (
                                                <>
                                                    {data?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('sr') && (
                                                                <td className='ps-4'>
                                                                    {(pageNumber - 1) * pageSize + index + 1}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('user') && (
                                                                <td>{data?.userFullName}</td>
                                                            )}
                                                            {hiddenColumns.includes('fileName') && (
                                                                <td>{data?.fileName}</td>
                                                            )}
                                                            {hiddenColumns.includes('bcEmployeeId') && (
                                                                <td>{data?.bcEmployeeId}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseReportId') && (
                                                                <td>{data?.expenseReportId}</td>
                                                            )}
                                                            {hiddenColumns.includes('expenseCategory') && (
                                                                <td>{data?.expenseCategory?.title}</td>
                                                            )}
                                                            {hiddenColumns.includes('parsingStatus') && (
                                                                <td className='ps-11'> {data?.parsingStatus === true ?
                                                                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.parsed}</span> :
                                                                    <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.failed}</span>}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('accountNumber') && (
                                                                <td>{data?.accountNumber}</td>
                                                            )}
                                                            {hiddenColumns.includes('bankVendorName') && (
                                                                <td>{data?.bankVendorName}</td>
                                                            )}

                                                            {hiddenColumns.includes('itemImportDate') && (
                                                                <td className='text-end'><DateTimeComp formattedDate={data?.itemImportDate} /></td>
                                                            )}
                                                            {hiddenColumns.includes('ccTotalAmount') && (
                                                                <td className='text-end'><CurrencyComp amount={data?.ccTotalAmount} /></td>
                                                            )}
                                                            {hiddenColumns.includes('itemDescription') && (
                                                                <td colSpan={2}>{data?.itemDescription}</td>
                                                            )}
                                                            {/* <td>
                                                                {hiddenColumns.includes('actions') && (
                                                                    <p className='text-end pe-9 text-primary mb-0'
                                                                        onClick={() => {
                                                                            setUserBankFeedData(data);
                                                                            bankFeedDetailHandler();
                                                                        }}>
                                                                        View Detail
                                                                    </p>
                                                                )}
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                                </>) : (
                                                <tr>
                                                    <td colSpan={20}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={20} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                {/* end::Table body */}
                            </table>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserCCFeeds