import React, { useCallback, useEffect, useState } from 'react'
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import { useGetAllBankAndUserLineItemsQuery } from '../../../../services/AutomationApi';
import { debounce } from 'lodash';
import labelKey from '../../../localization/label.json';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import DateComp from '../../../../components/dateComponent/DateComp';
import { constraintConfig } from '../../../../constraintConfig';
import CurrencyComp from '../../../../components/currencyComponent/CurrencyComp';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import SearchSelect from '../../../../components/textFields/SearchSelect';
import { useGetAllUsersManagerAdminQuery } from '../../../../services/GeneralApi';
import DateRangeComp from '../../../../components/dateComponent/DateRangeComp';
import { Spinner } from 'react-bootstrap';
import moment from 'moment-timezone';
import MergeLineItemModal from './MergeLineItemModal';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import { FaEye } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import ReactReadMoreReadLess from "react-read-more-read-less";
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetVendorLookUpQuery } from '../../../../services/VendorApi';

interface LightboxData {
    imageURL: string;
}
const MergeLineItems = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'mergeLineItems' });
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("ccItemImportDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("ccItemImportDate");
    const [filterByStatus, setFilterByStatus] = useState(true);
    const [activeTab, setActiveTab] = useState(true);
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [filterByUserId, setFilterByUserId] = useState('');
    const [localFilterByUserId, setLocalFilterByUserId] = useState('');
    const [filterByVendor, setFilterByVendor] = useState("");
    const [localFilterByVendor, setLocalFilterByVendor] = useState("");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);
    // Debounced search state
    // const [searchStr, setSearchStr] = useState('');
    const { data: allsuersLookup } = useGetAllUsersManagerAdminQuery('')
    const { data: vendor } = useGetVendorLookUpQuery('')

    const { data, refetch } = useGetAllBankAndUserLineItemsQuery({
        orgUserId: filterByUserId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        sortBy: sortBy,
        sortOrder: sortOrder,
        IslinkedItems: filterByStatus,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        vendorId: filterByVendor,

    })
    useEffect(() => {
        setIsLoading(true); // Set loading to true when filter changes
        refetch().finally(() => setIsLoading(false)); // Finally, set loading to false when refetch is done
    }, [filterByStatus, pageNumber, pageSize, refetch]);

    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const handleUserChange = (e: any) => {
        setLocalFilterByUserId(e.value);
    };
    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const handleVendorChange = (e: any) => {
        setLocalFilterByVendor(e.value);
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByUserId(localFilterByUserId)
        setFilterByVendor(localFilterByVendor)
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByUserId('')
        setFilterByVendor('')

        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByUserId('')
        setLocalFilterByVendor('')

    }

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    useEffect(() => {
        if (searchStr !== "" && sortOrder !== "" && sortBy !== "") {
            // Trigger the API call after the debounce time
            refetch();
        }
    }, [searchStr, sortOrder, sortBy, refetch]);
    // const [hiddenColumns, setHiddenColumns] = useState<string[]>(['cCTransactionID', 'lineItemDetailEntityId', 'ccVendorName', 'employeeId', 'ccItemImportDate', 'ccMerchantType', 'ccItemDescription', 'cctransactionDate', 'productCode', 'ccTotalAmount', 'userFullName', 'expenseReportId', 'ssExpenseCategoryName', 'ssVendorName', 'ssItemDate', 'ssItemAmount', 'currency', 'itemDebitOrCreditIndicator', 'debitOrCreditIndicator', 'ssAttachment', 'ccReportId', 'ccAttachement', 'paymentMethod', 'ccLineItemId', 'ccSource', 'ssPaymentMethod', 'ssItemDescription', 'ssCreatedDate', 'ssLineItemId', 'ssSource', 'ccCategory', 'ssCategory']);
    // const handleToggleColumn = (columnId: string) => {
    //     if (hiddenColumns.includes(columnId)) {
    //         setHiddenColumns(hiddenColumns.filter((id) => id !== columnId));
    //     } else {
    //         setHiddenColumns([...hiddenColumns, columnId]);
    //     }
    // };
    const handleTabChange = (status: boolean) => {
        setActiveTab(status);
        setFilterByStatus(status);
        setTimeout(() => {
            refetch();
        }, 200);
    };

    const [selectedBankData, setSelectedBankData] = useState(null);
    const [selectedDraftData, setSelectedDraftData] = useState(null);

    // Handle radio button selection
    const handleBankDataRadioSelect = (bankData: any) => {
        setSelectedBankData(bankData);
    };

    const handleDraftDataRadioSelect = (DraftData: any) => {
        setSelectedDraftData(DraftData);
    };


    const [showMergeLineItemModal, setShowMergeLineItemModal] = useState(false)

    const mergeLineItemHandler = () => {
        setShowMergeLineItemModal((prev) => !prev);
    };

    useEffect(() => {
        if (showMergeLineItemModal === false) {
            setSelectedBankData(null)
            setSelectedDraftData(null)
        }
    }, [showMergeLineItemModal])

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    return (
        <>
            <MergeLineItemModal
                show={showMergeLineItemModal}
                handleClose={() => setShowMergeLineItemModal(false)}
                selectedBankData={selectedBankData}
                selectedDraftData={selectedDraftData}
                refetch={refetch} />
            {lightboxOpen && (
                <Lightbox
                    mainSrc={lightboxData.imageURL}
                    onCloseRequest={() => setLightboxOpen(false)}
                    imageCaption="Attachment"
                    enableZoom={true}
                    imagePadding={50}
                />
            )}
            <div className='card mb-5'>

                <div className='card-header border-0 pb-0'>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className='btn-group overflow-auto' style={{ minWidth: "max-content" }}>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === true ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(true)}
                            >
                                {labelKey.lineItems}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === false ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(false)}
                            >
                                {labelKey.lineItemsPending}
                            </button>
                        </div>
                        {/* <SearchBarComponent
                            placeholder='User Name'
                            value={immediateSearchStr}
                            onChange={handleSearchChange} // Use immediate search handler
                        /> */}
                        <button className='btn btn-sm btn-light-primary'
                            onClick={() => mergeLineItemHandler()}
                            disabled={!selectedBankData || !selectedDraftData}
                        >
                            {labelKey.mergeLineItem}
                        </button>
                    </div>
                </div>
                <div className='card-body py-8 pt-0'>
                    <div className="row align-items-end">
                        {/* <div className="col-md-4 col-lg-3 col-xl-2 mb-5">
                            <SearchBarComponent
                                placeholder='File Parsing'
                                value={immediateSearchStr}
                                onChange={handleSearchChange}
                            />
                        </div> */}

                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <SearchSelect
                                label={`${labelKey.user}:`}
                                options={[
                                    { value: '', label: 'Select User' }, // Add the empty option
                                    ...(Array.isArray(allsuersLookup?.result) ? allsuersLookup.result.map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })) : [])
                                ]}
                                placeholder={labelKey.selectUser}
                                value={localFilterByUserId && localFilterByUserId !== ''
                                    ? {
                                        value: localFilterByUserId,
                                        label: allsuersLookup?.result.find((option: any) => option.id === localFilterByUserId)?.value
                                    }
                                    : null
                                }
                                onChange={handleUserChange}
                            />
                        </div>

                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <DateRangeComp
                                startDate={localFilterByStartDate || moment()}
                                endDate={localFilterByEndDate || moment()}
                                onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <SearchSelect
                                label={`${labelKey.vendor}:`}
                                options={[
                                    { value: '', label: 'Select Vendor' }, // Add the empty option
                                    ...(Array.isArray(vendor?.result) ? vendor.result.map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })) : [])
                                ]}
                                placeholder={labelKey.selectVendor}
                                value={localFilterByVendor && localFilterByVendor !== ''
                                    ? {
                                        value: localFilterByVendor,
                                        label: vendor?.result.find((option: any) => option.id === localFilterByVendor)?.value
                                    }
                                    : null
                                }
                                onChange={handleVendorChange}
                            />
                        </div>
                        <div className='col-md-4 col-lg-3 col-xl-2 mb-5'>
                            <div className='d-flex align-items-end h-100 gap-3'>
                                <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>

                                <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                    {isApplyingFilters ? <>
                                        <span>loading...</span>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </> : "Apply Filters"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>

                                            <>
                                                <th></th>
                                                {hiddenColumns.includes('cctransactionDate') && (
                                                    <TableHeading
                                                        label={labelKey.itemDate}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='cctransactionDate'
                                                        className="w-100px text-end"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccCategory') && (
                                                    <TableHeading
                                                        label={labelKey.category}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccCategory'
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccTotalAmount') && (
                                                    <TableHeading
                                                        label={labelKey.ccTotalAmount}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccTotalAmount pe-4'
                                                        className="w-100px"
                                                    />
                                                )}
                                                {/* {hiddenColumns.includes('ccMerchantType') && (
                                                    <TableHeading
                                                        label={labelKey.merchantType}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccMerchantType'
                                                        className="ps-4"
                                                    />
                                                )} */}

                                                {hiddenColumns.includes('ccVendorName') && (
                                                    <TableHeading
                                                        label={labelKey.vendor}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccVendorName'
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccAttachement') && (
                                                    <TableHeading
                                                        label={labelKey.attachment}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccAttachement'
                                                        className="pe-4"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccReportId') && (
                                                    <TableHeading
                                                        label={labelKey.reportId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccReportId'
                                                        className="pe-4"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccPyamentMethod') && (
                                                    <TableHeading
                                                        label={labelKey.paymentMethod}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccPyamentMethod'
                                                        className="pe-4"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccItemDescription') && (
                                                    <TableHeading
                                                        label={labelKey.itemDescription}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccItemDescription'
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccItemImportDate') && (
                                                    <TableHeading
                                                        label={labelKey.createdDate}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccItemImportDate'
                                                        className="w-100px text-end pe-15"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccLineItemId') && (
                                                    <TableHeading
                                                        label={labelKey.ccLineItemId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccLineItemId'
                                                        className="w-100px text-end pe-15"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ccSource') && (
                                                    <TableHeading
                                                        label={labelKey.source}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ccSource'
                                                        className="w-100px text-end pe-15"
                                                    />
                                                )}
                                                <th className='text-end rounded-end pe-2 border-0'>
                                                    <TableSettingMenu tooltipContent={false}>
                                                        <TextField
                                                            rightLabel={labelKey.itemDate}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("cctransactionDate")}
                                                            checked={hiddenColumns.includes('cctransactionDate')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.category}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccCategory")}
                                                            checked={hiddenColumns.includes('ccCategory')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.itemAmount}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccTotalAmount")}
                                                            checked={hiddenColumns.includes('ccTotalAmount')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.vendor}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccVendorName")}
                                                            checked={hiddenColumns.includes('ccVendorName')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.attachment}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccAttachement")}
                                                            checked={hiddenColumns.includes('ccAttachement')}
                                                            fieldClass='mb-4'
                                                        />

                                                        <TextField
                                                            rightLabel={labelKey.reportId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccReportId")}
                                                            checked={hiddenColumns.includes('ccReportId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.paymentMethod}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccPyamentMethod")}
                                                            checked={hiddenColumns.includes('ccPyamentMethod')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.itemDescription}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccItemDescription")}
                                                            checked={hiddenColumns.includes('ccItemDescription')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.createdDate}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccItemImportDate")}
                                                            checked={hiddenColumns.includes('ccItemImportDate')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.ccLineItemId}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccLineItemId")}
                                                            checked={hiddenColumns.includes('ccLineItemId')}
                                                            fieldClass='mb-4'
                                                        />
                                                        <TextField
                                                            rightLabel={labelKey.source}
                                                            type="checkbox"
                                                            onChange={() => handleToggleColumn("ccSource")}
                                                            checked={hiddenColumns.includes('ccSource')}
                                                            fieldClass='mb-4'
                                                        />
                                                    </TableSettingMenu>
                                                </th>

                                            </>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.automation_CCFeed_LineItemVM?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.automation_CCFeed_LineItemVM?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                <td className='ps-4'>
                                                                    <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                                        <input type="radio" className='form-check-input'
                                                                            checked={selectedBankData === data}
                                                                            name='bankData'
                                                                            onChange={() => handleBankDataRadioSelect(data)}
                                                                        //  checked={selectedTransactionId === item.financialTransactionEntityId}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                {hiddenColumns.includes('cctransactionDate') && (
                                                                    <td className='text-end'><DateComp formattedDate={data?.transactionDate} /></td>
                                                                )}
                                                                {hiddenColumns.includes('ccCategory') && (
                                                                    <td>{data?.expenseCategory?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('ccTotalAmount') && (
                                                                    <td className={`text-end pe-4 ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                        <CurrencyComp amount={data?.ccTotalAmount} />
                                                                    </td>
                                                                )}
                                                                {/* {hiddenColumns.includes('ccMerchantType') && (
                                                                    <td>{data?.merchantType ? data?.merchantType : "N/A"}</td>
                                                                )} */}

                                                                {hiddenColumns.includes('ccVendorName') && (
                                                                    <td>{data?.vendor?.name}</td>
                                                                )}
                                                                {hiddenColumns.includes('ccAttachement') && (
                                                                    <td>
                                                                        {data?.attachmentFile === null || data?.attachmentFile === "null" || data?.attachmentFile === "undefined" || data?.attachmentFile === "" ? (
                                                                            null
                                                                        ) : <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(data?.attachmentFile)} />}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('ccReportId') && (
                                                                    <td>{data?.expenseReportId}</td>
                                                                )}
                                                                {hiddenColumns.includes('ccPyamentMethod') && (
                                                                    <td>{data?.expensePaymentMethod?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('ccItemDescription') && (
                                                                    <td>
                                                                        <div className='w-200px text-wrap'>
                                                                            {data && data.ccItemDescription && <ReactReadMoreReadLess
                                                                                charLimit={30}
                                                                                readMoreText={"Read more"}
                                                                                readLessText={"Read less"}
                                                                                readMoreClassName="readMore"
                                                                                readLessClassName="readLess"
                                                                            >
                                                                                {data.ccItemDescription}
                                                                            </ReactReadMoreReadLess>}
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('ccItemImportDate') && (
                                                                    <td><DateComp formattedDate={data?.itemImportDate} /> </td>
                                                                )}

                                                                {hiddenColumns.includes('ccLineItemId') && (
                                                                    <td>{data?.ccLineItemId} </td>
                                                                )}

                                                                {hiddenColumns.includes('ccSource') && (
                                                                    <td className='ps-5'>
                                                                        <Tooltip id="web-source" place="top" />
                                                                        <Tooltip id="mobile-source" place="top" />
                                                                        <Tooltip id="bank-source" place="top" />

                                                                        {data?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                            <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                            :
                                                                            data?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                    data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                data?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                    <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                        data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                    : null}
                                                                    </td>
                                                                )}

                                                                {/* {hiddenColumns.includes('cCTransactionID') && (
                                                                    <td className='ps-4'>{data?.processorTransactionId ? data?.processorTransactionId : "N/A"}</td>
                                                                )} */}


                                                                {/* {hiddenColumns.includes('productCode') && (
                                                        <td>
                                                            {data?.productCode}
                                                        </td>
                                                    )} */}
                                                                {/* 
                                                               
                                                                
                                                                {hiddenColumns.includes('debitOrCreditIndicator') && (
                                                                    <td className={`text-center ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>{data?.debitOrCreditIndicator}</td>
                                                                )} */}

                                                            </tr>
                                                        ))}
                                                    </>) : (
                                                    <tr>
                                                        <td colSpan={20}>
                                                            <NoRecordFound />
                                                        </td>
                                                    </tr>
                                                )}
                                            </>}
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={20} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <>
                                                <th></th>
                                                {hiddenColumns.includes('ssItemDate') && (
                                                    <TableHeading
                                                        label={labelKey.itemDate}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssItemDate'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssExpenseCategoryName') && (
                                                    <TableHeading
                                                        label={labelKey.category}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssExpenseCategoryName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssItemAmount') && (
                                                    <TableHeading
                                                        label={labelKey.itemAmount}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssItemAmount'
                                                        className="pe-4 w-100px"
                                                        labelClassName="justify-content-end text-primary"
                                                    />
                                                )}

                                                {hiddenColumns.includes('ssVendorName') && (
                                                    <TableHeading
                                                        label={labelKey.vendor}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssVendorName'
                                                        labelClassName="text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssAttachment') && (
                                                    <TableHeading
                                                        label={labelKey.attachment}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssAttachment'
                                                        className="pe-4"
                                                        labelClassName="justify-content-end text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssReportId') && (
                                                    <TableHeading
                                                        label={labelKey.reportId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssReportId'
                                                        className="pe-4"
                                                        labelClassName="justify-content-end text-primary"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssPaymentMethod') && (
                                                    <TableHeading
                                                        label={labelKey.paymentMethod}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssPaymentMethod'
                                                        className="pe-4"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssItemDescription') && (
                                                    <TableHeading
                                                        label={labelKey.itemDescription}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssItemDescription'
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssCreatedDate') && (
                                                    <TableHeading
                                                        label={labelKey.createdDate}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssCreatedDate'
                                                        className="w-100px text-end pe-15"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssLineItemId') && (
                                                    <TableHeading
                                                        label={labelKey.ssLineItemId}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssLineItemId'
                                                        className="w-100px text-end pe-15"
                                                    />
                                                )}
                                                {hiddenColumns.includes('ssSource') && (
                                                    <TableHeading
                                                        label={labelKey.source}
                                                        sortedColumn={sortedColumn}
                                                        sortOrder={sortOrder}
                                                        handleSort={handleSort}
                                                        columnId='ssSource'
                                                        className="w-100px text-end pe-15"
                                                    />
                                                )}
                                            </>


                                            <th className='text-end rounded-end pe-2 border-0'>
                                                <TableSettingMenu tooltipContent={false}>
                                                    <TextField
                                                        rightLabel={labelKey.itemDate}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssItemDate")}
                                                        checked={hiddenColumns.includes('ssItemDate')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.category}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssExpenseCategoryName")}
                                                        checked={hiddenColumns.includes('ssExpenseCategoryName')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.itemAmount}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssItemAmount")}
                                                        checked={hiddenColumns.includes('ssItemAmount')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.vendor}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssVendorName")}
                                                        checked={hiddenColumns.includes('ssVendorName')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.attachment}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssAttachment")}
                                                        checked={hiddenColumns.includes('ssAttachment')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.reportId}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssReportId")}
                                                        checked={hiddenColumns.includes('ssReportId')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.paymentMethod}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssPaymentMethod")}
                                                        checked={hiddenColumns.includes('ssPaymentMethod')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.itemDescription}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssItemDescription")}
                                                        checked={hiddenColumns.includes('ssItemDescription')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.createdDate}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssCreatedDate")}
                                                        checked={hiddenColumns.includes('ssCreatedDate')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.ssLineItemId}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssLineItemId")}
                                                        checked={hiddenColumns.includes('ssLineItemId')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.source}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("ssSource")}
                                                        checked={hiddenColumns.includes('ssSource')}
                                                        fieldClass='mb-4'
                                                    />
                                                </TableSettingMenu>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.expenseDraftItems?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.expenseDraftItems?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                {/* {activeTab === true && */}
                                                                <>
                                                                    <td className='ps-4'>
                                                                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                                            <input type="radio"
                                                                                className='form-check-input'
                                                                                name='draftData'
                                                                                checked={selectedDraftData === data}
                                                                                onChange={() => handleDraftDataRadioSelect(data)} />
                                                                        </div>
                                                                    </td>
                                                                    {hiddenColumns.includes('ssItemDate') && (
                                                                        <td>
                                                                            <DateComp formattedDate={data?.itemDate} />
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssExpenseCategoryName') && (
                                                                        <td>
                                                                            {data?.expenseCategory?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssItemAmount') && (
                                                                        <td className={`text-end pe-4 ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                            <CurrencyComp amount={data?.amount} />
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssVendorName') && (
                                                                        <td>
                                                                            {data?.vendor?.name}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssAttachment') && (
                                                                        <td>
                                                                            {data?.attachmentFile === null || data?.attachmentFile === "null" || data?.attachmentFile === "undefined" || data?.attachmentFile === "" ? (
                                                                                null
                                                                            ) : <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(data?.attachmentFile)} />}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssReportId') && (
                                                                        <td>
                                                                            {data?.trackingId}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssPaymentMethod') && (
                                                                        <td>
                                                                            {data?.paymentMethod?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssItemDescription') && (
                                                                        <td>
                                                                            <div className='w-200px text-wrap'>
                                                                                {data && data.itemDetail && <ReactReadMoreReadLess
                                                                                    charLimit={30}
                                                                                    readMoreText={"Read more"}
                                                                                    readLessText={"Read less"}
                                                                                    readMoreClassName="readMore"
                                                                                    readLessClassName="readLess"
                                                                                >
                                                                                    {data.itemDetail}
                                                                                </ReactReadMoreReadLess>}
                                                                            </div>
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('ssCreatedDate') && (
                                                                        <td><DateComp formattedDate={data?.createdDate} /> </td>
                                                                    )}

                                                                    {hiddenColumns.includes('ssLineItemId') && (
                                                                        <td>{data?.expenseItemId} </td>
                                                                    )}

                                                                    {hiddenColumns.includes('ssSource') && (
                                                                        <td className='ps-5'>
                                                                            <Tooltip id="web-source" place="top" />
                                                                            <Tooltip id="mobile-source" place="top" />
                                                                            <Tooltip id="bank-source" place="top" />

                                                                            {data?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                    data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                :
                                                                                data?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                    <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                        data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                    data?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                        <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                            data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                        : null}
                                                                        </td>
                                                                    )}
                                                                    {/* {hiddenColumns.includes('employeeId') && (
                                                                        <td className='ps-4'>{data?.employeeId}</td>
                                                                    )} */}
                                                                    {/* {hiddenColumns.includes('userFullName') && (
                                                                        <td>
                                                                            {data?.userFullName}
                                                                        </td>
                                                                    )}
                                                                    
                                                                   
                                                                    {hiddenColumns.includes('currency') && (
                                                                        <td className='text-center'>
                                                                            {data?.currency?.title}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('itemDebitOrCreditIndicator') && (
                                                                        <td className={`text-center ${data?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                            {data?.itemDebitOrCreditIndicator}
                                                                        </td>
                                                                    )} */}

                                                                </>
                                                                {/* } */}
                                                            </tr>
                                                        ))}
                                                    </>) : (
                                                    <tr>
                                                        <td colSpan={20}>
                                                            <NoRecordFound />
                                                        </td>
                                                    </tr>
                                                )}
                                            </>}
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={20} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Pagination
                                totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                                toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                                ofResult={data?.result?.totalRecords}
                                onChange={handlePageSizeChange}
                                pageSize={pageSize}
                                onPageChange={handlePageClick}
                                pageCount={data?.result?.totalPages || 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MergeLineItems