import 'bootstrap-daterangepicker/daterangepicker.css';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'react-image-lightbox/style.css';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { KTSVG } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateComp from '../../../components/dateComponent/DateComp';
import FilterMenu from '../../../components/filterMenu/FilterMenu';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import Pagination from '../../../components/pagination/Pagination';
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import { constraintConfig } from '../../../constraintConfig';
import { selectAuth } from '../../../features/authSlice';
import { useAppSelector } from '../../../hooks';
import { useGetExpenseOfManagerTeamQuery } from '../../../services/ExpenseApi';
import { useExpenseCategoryLookupQuery, useGetAllExpenseTypeQuery, useGetManagerTeamLookupQuery } from '../../../services/GeneralApi';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import labelKey from "../../localization/label.json";
import AssignToExistingExpenseModal from './AssignToExistingExpenseModal';
import AssignToNewExpenseModal from './AssignToNewExpenseModal';
import DeleteExpenseModal from './DeleteExpenseModal';
import { AddExpenseItemModal } from './update-single-Item/AddExpenseItemModal';

interface LightboxData {
    imageURL: string;
}

const BackUpApprovers = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'backupApprovers' });
    const [selectedExpenseItemIds, setSelectedExpenseItemIds] = useState<number[]>([]);
    const [isTableHeadChecked, setIsTableHeadChecked] = useState(false);

    const [localFilterByCategory, setlocalFilterByCategory] = useState("");

    const location = useLocation();
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { userAccountID, roleID, token, baseURL } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(' '); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("trackingId");
    const [sortOrder, setSortOrder] = useState("desc");
    // const [filterByDate, setFilterByDate] = useState("");
    const [filterByStatus, setFilterByStatus] = useState("Pending");
    const [filterByExpType, setFilterByExpType] = useState("0");
    const [sortedColumn, setSortedColumn] = useState("trackingId");
    const [checkedBoxes, setCheckedBoxes] = useState<string[]>([]);
    const [searchUser, setSearchUser] = useState('');
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByExpType, setLocalFilterByExpType] = useState("0");
    const [localCheckedBoxes, setLocalCheckedBoxes] = useState<string[]>([]);
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);


    const { data: expenseCategory } = useExpenseCategoryLookupQuery('')
    const { data: getManagerTeamLookup } = useGetManagerTeamLookupQuery({ managerId: userAccountID });
    const { data: getAllExpenseType } = useGetAllExpenseTypeQuery('')


    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>, userId: string) => {
        const checkboxId = userId;
        const isChecked = e.target.checked;

        if (isChecked) {
            setLocalCheckedBoxes([...localCheckedBoxes, checkboxId]);
        } else {
            const updatedCheckedBoxes = localCheckedBoxes.filter((id) => id !== checkboxId);
            setLocalCheckedBoxes(updatedCheckedBoxes);
        }
    };
    // Function to filter users based on search input
    const filteredUsers = getManagerTeamLookup?.result.filter(
        (user: any) =>
            user.value.toLowerCase().includes(searchUser.toLowerCase())
    );

    // Handler for user search input change
    const handleUserSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchUser(e.target.value);
    };
    const { data, isLoading, refetch, isSuccess } = useGetExpenseOfManagerTeamQuery({
        managerId: userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        filterByExpType: filterByExpType,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        filterByStatus: filterByStatus,
        filterbyUser: checkedBoxes.length > 0 ? checkedBoxes : null,
    })

    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const handleFilterByExpType = (selectedOption: any) => {
        setLocalFilterByExpType(selectedOption.value);
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByExpType(localFilterByExpType);
        setCheckedBoxes(localCheckedBoxes)
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByExpType("0");
        setSearchUser('');
        setCheckedBoxes([]);
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByExpType("0");
        setLocalCheckedBoxes([]);
    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);


    useEffect(() => {
        if (filterByStatus || filterByStatus === "" ||
            searchStr === "" ||
            sortOrder === "asc" ||
            sortBy === "") {
            refetch();
        }
    }, [filterByStatus, sortOrder, sortBy, searchStr]);

    const [showAddExpenseItemModal, setShowAddExpenseItemModal] = useState(false)

    const [showUpdateExpenseItemModal, setShowUpdateExpenseItemModal] = useState(false)
    const [showExpenseItemData, setShowExpenseItemData] = useState({})
    const [viewOnlyData, setViewOnlyData] = useState(false)

    const expenseItemModalHandler = () => {
        setShowUpdateExpenseItemModal((prev) => !prev);
    };


    // Function to handle the table head checkbox change
    // const handleTableHeadChange = (e: any) => {
    //     const isChecked = e.target.checked;
    //     setIsTableHeadChecked(isChecked);

    //     // If the table head checkbox is checked, set all checkboxes in the table body to be checked
    //     if (isChecked) {
    //         const allExpenseItemIds = data?.result?.data?.map((data: any) => data.expenseItemId) || [];

    //         // setSelectedExpenseItemIds(allExpenseItemIds);
    //         setSelectedExpenseItemIds((prevIds) => {
    //             // Combine the previous selected items with the current page items
    //             const updatedIds = Array.from(new Set([...prevIds, ...allExpenseItemIds]));
    //             return updatedIds;
    //         });
    //     } else {
    //         // If the table head checkbox is unchecked, clear the selection in the table body
    //         setSelectedExpenseItemIds([]);
    //     }
    //     // Update isTableHeadChecked state
    //     setIsTableHeadChecked(isChecked);
    // };
    const handleTableHeadChange = (e: any) => {
        const isChecked = e.target.checked;
        // console.log(`Table head checked: ${isChecked}`); 
        setIsTableHeadChecked(isChecked);
        const allExpenseItems = data?.result?.data || [];
        // console.log('All expense items:', allExpenseItems); 

        const filteredExpenseItems = allExpenseItems.filter((item: any) => item?.paymentMethod?.id !== 3);
        // console.log('Filtered expense items:', filteredExpenseItems);
        if (isChecked) {
            const allExpenseItemIds = filteredExpenseItems.map((item: any) => item.expenseId);
            // console.log('Expense item IDs to be checked:', allExpenseItemIds);

            setSelectedExpenseItemIds((prevIds) => {
                const updatedIds = Array.from(new Set([...prevIds, ...allExpenseItemIds]));
                // console.log('Updated selected expense item IDs:', updatedIds);
                return updatedIds;
            });
        } else {
            setSelectedExpenseItemIds([]);
        }

        // Update isTableHeadChecked state
        //  setIsTableHeadChecked(isChecked);

        setIsTableHeadChecked(isChecked && filteredExpenseItems.length === allExpenseItems.length);

    };



    // handleCheckbox
    const handleCheckItem = (e: any, itemId: any) => {
        const isChecked = e.target.checked;

        setSelectedExpenseItemIds((prevItems) => {
            // Create a copy of the previous selectedExpenseItemIds array
            const updatedIds = [...prevItems];

            if (isChecked) {
                // If the checkbox is checked, add the itemId to the selectedExpenseItemIds array
                updatedIds.push(itemId);
            } else {
                // If the checkbox is unchecked, remove the itemId from the selectedExpenseItemIds array
                const index = updatedIds.indexOf(itemId);
                if (index !== -1) {
                    updatedIds.splice(index, 1);
                }
            }

            // Update the table head checkbox state based on the current state of the checkboxes in the body
            // setIsTableHeadChecked(updatedIds.length === data?.result?.data?.length);
            setIsTableHeadChecked(updatedIds.length === pageSize * (page - 1) + data?.result?.data?.length);

            return updatedIds;
        });
    };

    useEffect(() => {
        setIsTableHeadChecked(false); // Reset isTableHeadChecked when page changes
    }, [page]);

    const resetCheckboxState = () => {
        setIsTableHeadChecked(false);
        setSelectedExpenseItemIds([]);
    };
    // console.log('selectedExpenseItemIds', selectedExpenseItemIds);

    // Whether any checkbox in the table body is checked
    const isAnyCheckboxChecked = selectedExpenseItemIds.length > 0 || isTableHeadChecked;

    const [showAddNewExpenseModal, setShowAddNewExpenseModal] = useState(false)
    const addNewExpenseModalHandler = () => {
        setShowAddNewExpenseModal((prev) => !prev);
    };

    const [showAddExistingExpensetModal, setShowAddExistingExpenseModal] = useState(false)
    const eidtProjectModalHandler = () => {
        setShowAddExistingExpenseModal((prev) => !prev);
    };

    const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState(false)
    const [expenseDeleteData, setExpenseDeleteData] = useState({});

    const deleteHandler = () => {
        setShowExpenseDeleteModal((prev) => !prev);
    };

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    const [prevModalState, setPrevModalState] = useState({
        updateExpenseItemModal: false,
        addExpenseItemModal: false,
        addNewExpenseModal: false,
        addExistingExpenseModal: false,
        showExpenseDeleteModal: false
    });

    useEffect(() => {
        setPrevModalState({
            updateExpenseItemModal: showUpdateExpenseItemModal,
            addExpenseItemModal: showAddExpenseItemModal,
            addNewExpenseModal: showAddNewExpenseModal,
            addExistingExpenseModal: showAddExistingExpensetModal,
            showExpenseDeleteModal: showExpenseDeleteModal
        });
    }, [
        showUpdateExpenseItemModal,
        showAddExpenseItemModal,
        showAddNewExpenseModal,
        showAddExistingExpensetModal,
        showExpenseDeleteModal
    ]);

    useEffect(() => {
        if (
            (prevModalState.updateExpenseItemModal && !showUpdateExpenseItemModal) ||
            (prevModalState.addExpenseItemModal && !showAddExpenseItemModal) ||
            (prevModalState.addNewExpenseModal && !showAddNewExpenseModal) ||
            (prevModalState.addExistingExpenseModal && !showAddExistingExpensetModal) ||
            (prevModalState.showExpenseDeleteModal && !showExpenseDeleteModal)

        ) {
            refetch();
            resetCheckboxState()
        }
    }, [
        showUpdateExpenseItemModal,
        showAddExpenseItemModal,
        showAddNewExpenseModal,
        showAddExistingExpensetModal,
        prevModalState.updateExpenseItemModal,
        prevModalState.addExpenseItemModal,
        prevModalState.addNewExpenseModal,
        prevModalState.addExistingExpenseModal,
        prevModalState.showExpenseDeleteModal,
        refetch,
        resetCheckboxState
    ]);
    return (
        <>
            <AddExpenseItemModal
                show={showAddExpenseItemModal}
                handleClose={() => setShowAddExpenseItemModal(false)}
                viewOnlyData={false} />

            <AssignToNewExpenseModal
                show={showAddNewExpenseModal}
                handleClose={() => setShowAddNewExpenseModal(false)}
                selectedExpenseItemIds={selectedExpenseItemIds}
            />
            <AssignToExistingExpenseModal
                show={showAddExistingExpensetModal}
                handleClose={() => setShowAddExistingExpenseModal(false)}
                selectedExpenseItemIds={selectedExpenseItemIds}
            />
            <DeleteExpenseModal
                show={showExpenseDeleteModal}
                handleClose={() => setShowExpenseDeleteModal(false)}
                data={expenseDeleteData} />
            <PageTitle breadcrumbs={[]}>{labelKey.expenseLineItems}</PageTitle>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <SearchBarComponent
                        placeholder='Expense Item'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <div className='d-md-flex align-items-center'>
                        {/* {isAnyCheckboxChecked && ( */}
                        <div className="d-flex align-items-center gap-2 mt-2 mt-lg-0">
                            <button className={`btn btn-sm btn-light-primary me-3`}
                                onClick={() => {
                                    eidtProjectModalHandler();
                                }}
                                disabled={isAnyCheckboxChecked === false}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' />
                                Re-Assign Expense
                            </button>
                        </div>
                        {/* )} */}
                        <div className='d-flex align-items-center gap-2 mt-2 mt-lg-0'>
                            <FilterMenu
                                onclickApplyFilters={applyFilters}
                                onclickResetFilters={resetFilters}
                                isApplyingFiltersLoading={isApplyingFilters}
                            >
                                <>
                                    <div className='mb-5'>
                                        <label className='form-label fw-bold text-dark fs-6'>Date:</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className="form-control form-control-lg form-control-solid default-input"
                                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker>
                                    </div>
                                    <div>
                                        <label className='form-label fs-5 fw-bold'>{labelKey.expenseType}:</label>
                                        <Select
                                            options={[
                                                { value: '0', label: 'All' }, // Add the empty option
                                                ...(getAllExpenseType?.result || []).map((option: any) => ({
                                                    value: option.id,
                                                    label: option?.value,
                                                })),
                                            ]}
                                            className='mb-5'
                                            placeholder={
                                                localFilterByExpType
                                                    ? getAllExpenseType?.result.find((option: any) => option.id === localFilterByExpType)?.value
                                                    : 'Select Type'
                                            }
                                            value={localFilterByExpType}
                                            onChange={handleFilterByExpType}
                                        />
                                    </div>
                                    {roleID === constraintConfig.roleID.role4 &&
                                        <div className='mt-3'>
                                            <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                                            <input
                                                type='text'
                                                className='form-control form-control-sm'
                                                placeholder='Search User...'
                                                value={searchUser}
                                                onChange={handleUserSearchChange}
                                            />
                                            <div className='mh-200px overflow-auto mt-2'>
                                                {filteredUsers?.map((user: any) => (
                                                    <div key={user.id}>
                                                        <div className='d-flex align-items-center gap-3 mb-2'>
                                                            <TextField
                                                                type="checkbox"
                                                                fieldClass='cursor-pointer'
                                                                onChange={(e) => handleUserChange(e, user.id)}
                                                                value={user.id}
                                                                checked={localCheckedBoxes.includes(user.id)}
                                                            />
                                                            <span className='fs-5 fw-semibold text-capitalize'>{user.value}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }
                                </>
                            </FilterMenu>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('checkbox') && (
                                        <TableHeading
                                            columnId='checkbox'
                                            className="ps-4"
                                        >
                                            {/* {data?.result?.data?.ocrPassed && data?.result?.data?.ocrPassed !== false ? ( */}
                                            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                <input
                                                    type="checkbox"
                                                    className='form-check-input cursor-pointer'
                                                    checked={isTableHeadChecked} onChange={handleTableHeadChange}
                                                // disabled={data?.ocrPassed === false}
                                                />
                                            </div>
                                            {/* ) : null} */}
                                        </TableHeading>
                                    )}
                                    {/* {hiddenColumns.includes('sr') && (
                                <TableHeading
                                    label={labelKey.sr}
                                    columnId='sr'
                                />
                            )} */}
                                    {hiddenColumns.includes('trackingId') && (
                                        <TableHeading
                                            label={labelKey.reportId}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='trackingId'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('userAccount') && (
                                        <TableHeading
                                            label={labelKey.reportees}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='userAccount'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseType') && (
                                        <TableHeading
                                            label={labelKey.type}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseType'
                                        />
                                    )}
                                    {hiddenColumns.includes('title') && (
                                        <TableHeading
                                            label={labelKey.title}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='title'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseDetail') && (
                                        <TableHeading
                                            label={labelKey.description}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseDetail'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('amount') && (
                                        <TableHeading
                                            label={labelKey.totalAmount}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='amount'
                                        />
                                    )}
                                    {hiddenColumns.includes('reportDate') && (
                                        <TableHeading
                                            label={labelKey.date}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='reportDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseStatus') && (
                                        <TableHeading
                                            label={labelKey.status}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseStatus'
                                        />
                                    )}
                                    {hiddenColumns.includes('clarificationNote') && (
                                        <TableHeading
                                            label={labelKey.clarificationNote}
                                            columnId='clarificationNote'
                                            className='w-300px'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.checkbox}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("checkbox")}
                                                checked={hiddenColumns.includes('checkbox')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.reportId}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("trackingId")}
                                                checked={hiddenColumns.includes('trackingId')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.reportees}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("userAccount")}
                                                checked={hiddenColumns.includes('userAccount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.title}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("title")}
                                                checked={hiddenColumns.includes('title')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.description}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseDetail")}
                                                checked={hiddenColumns.includes('expenseDetail')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.totalAmount}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("amount")}
                                                checked={hiddenColumns.includes('amount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.date}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("reportDate")}
                                                checked={hiddenColumns.includes('reportDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.status}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseStatus")}
                                                checked={hiddenColumns.includes('expenseStatus')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.clarificationNote}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("clarificationNote")}
                                                checked={hiddenColumns.includes('clarificationNote')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {data?.result?.data?.length > 0 ? (
                                            <>
                                                {data?.result?.data?.map((data: any, index: any) => (
                                                    <tr
                                                        key={data?.expenseItemId}
                                                        className={`${selectedExpenseItemIds.includes(data.expenseItemId) ? 'highlighted-row' : ''}`}>
                                                        {hiddenColumns.includes('checkbox') && (
                                                            <td className='ps-4'>
                                                                <TextField
                                                                    type="checkbox"
                                                                    fieldClass='form-check-input cursor-pointer'
                                                                    checked={isTableHeadChecked || selectedExpenseItemIds.includes(data.expenseId)}
                                                                    onChange={(e) => handleCheckItem(e, data.expenseId)}
                                                                    disabled={data?.paymentMethod?.id === 3}
                                                                // disabled={data?.ocrPassed === false}
                                                                />
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('trackingId') && (
                                                            <td className='ps-5'>{data?.trackingId}</td>
                                                        )}
                                                        {hiddenColumns.includes('userAccount') && (
                                                            <td>
                                                                <Link to={`/user-profile/expense-items/${data.userAccount?.orgUserID}`}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='symbol symbol-50px me-5'>
                                                                            {/* {data?.userAccount?.imageUrl ? <img src={data?.userAccount?.imageUrl} alt="" /> : */}
                                                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userAccount?.firstName?.charAt(0)}</div>
                                                                            {/* } */}
                                                                        </div>
                                                                        <div className='d-flex justify-content-start flex-column'>
                                                                            <span className='text-capitalize mb-0'>
                                                                                {data?.userAccount?.firstName}  {data?.userAccount?.middleName} {data?.userAccount?.lastName}
                                                                            </span>
                                                                            <span className='text-muted fw-semibold text-muted d-block fs-7 text-lowercase'>
                                                                                {data?.userAccount?.email}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('expenseType') && (
                                                            <td>{data?.expenseType?.title}</td>
                                                        )}
                                                        {hiddenColumns.includes('title') && (
                                                            <td>{data?.title}</td>
                                                        )}
                                                        {hiddenColumns.includes('expenseDetail') && (
                                                            <td className='w-300px text-wrap'>
                                                                {data && data.expenseDetail && <ReactReadMoreReadLess
                                                                    charLimit={30}
                                                                    readMoreText={"Read more"}
                                                                    readLessText={"Read less"}
                                                                    readMoreClassName="readMore"
                                                                    readLessClassName="readLess"
                                                                >
                                                                    {data.expenseDetail}
                                                                </ReactReadMoreReadLess>}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('amount') && (
                                                            <td>
                                                                <CurrencyComp amount={data?.amount} />
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('reportDate') && (
                                                            <td><DateComp formattedDate={data?.reportDate} /></td>
                                                        )}
                                                        {hiddenColumns.includes('expenseStatus') && (
                                                            <td>
                                                                {data?.approverStatus?.title === "Pending" ?
                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.pending}</span> :
                                                                    data?.approverStatus?.title === "Approved" ?
                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.approved}</span> :
                                                                        data?.approverStatus?.title === "Rejected" ?
                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                                                                            // data?.approverStatus?.title === "Clarification" ?
                                                                            //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                            data?.approverStatus?.title === "Accounts Payable" ?
                                                                                <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.accountsPayable}</span> : ""
                                                                }
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('clarificationNote') && (
                                                            <td className='text-wrap w-300px'>
                                                                {data && data.clarificationNote && <ReactReadMoreReadLess
                                                                    charLimit={30}
                                                                    readMoreText={"Read more"}
                                                                    readLessText={"Read less"}
                                                                    readMoreClassName="readMore"
                                                                    readLessClassName="readLess"
                                                                >
                                                                    {data.clarificationNote}
                                                                </ReactReadMoreReadLess>}
                                                            </td>
                                                        )}
                                                        {hiddenColumns.includes('actions') && (
                                                            <td className='text-end' >
                                                                <div className='d-flex align-items-center justify-content-end gap-2' >

                                                                </div>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </>
                                        ) :
                                            (
                                                <tr>
                                                    <td colSpan={15}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={15} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table >
                    </div >
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                    />
                    {/* end::Table container */}
                </div >


                {/* begin::Body */}
            </div >
        </>
    )
}

export default BackUpApprovers