import { createRoot } from 'react-dom/client'
// Axios
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/style.scss'
import 'react-image-lightbox/style.css';
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppRoutes } from './app/routing/AppRoutes'
// import {AuthProvider, setupAxios} from './app/modules/auth'
import { Provider } from 'react-redux'
import { ThemeModeProvider } from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import './app/pages/App.css'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <MetronicI18nProvider>
            <ThemeModeProvider>
              {/* <AuthProvider> */}
              <AppRoutes />
              {/* </AuthProvider> */}
            </ThemeModeProvider>
          </MetronicI18nProvider>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </PersistGate>
    </Provider>

  )
}
